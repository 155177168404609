const prod = process.env.NODE_ENV === "production";
const dev = process.env.NODE_ENV === "development";
let API_URL, HOME_URL;
let SEARCH_SUGGEST_HOST; // This API is not being used and host is not publicly available
let INTERNAL_SERVICE2_V1_API, INTERNAL_SERVICE2_V1_QRCODE_API, EVENTS_LIST_URL, EVENT_INFO_V1_API, EVENT_DEV_API, UPLOAD_API_URL;
if (prod) {
  HOME_URL = "https://www.picxy.com";
  API_URL = "https://azure-picxy-api.picxy.com/";
  PICXY_O_EVENTS_URL = "https://azure-picxy-api.picxy.com/o-events/";
  UPLOAD_API = "https://azure-upload-api.picxy.com/";
  SEARCH_SUGGEST_HOST = "https://aws-search2.picxy.com/";
  CHECK_STATUS =
    "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/status/";
  INTERNAL_SERVICE2_V1_API = 'https://devinternal-api.picxy.com/service2/o-events/v1/'
  INTERNAL_SERVICE2_V1_QRCODE_API = 'https://devinternal-api.picxy.com/service2/'

  PICXY_API_O_EVENTS = 'https://devpicxy-api.picxy.com/o-events/'
  UPLOAD_API_NEW_URL = 'https://devupload-api.picxy.com/'
  UPLOAD_API_URL = 'https://azure-picxy-api.picxy.com/o-events/v1/'
  CREATE_SERVICE2_V1_API = 'https://azure-picxy-api.picxy.com/o-events/v1/'

  EVENT_DEV_API = 'https://azure-picxy-api.picxy.com/o-events/v1/'

  } else {
  HOME_URL = "https://dev.picxy.com";
  API_URL = "https://devpicxy-api.picxy.com/";
  PICXY_O_EVENTS_URL = "https://devpicxy-api.picxy.com/o-events/";
  UPLOAD_API = "https://devupload-api.picxy.com//";
  // UPLOAD_API = "http://164.52.204.184:8001/";
  SEARCH_SUGGEST_HOST = "http://164.52.204.184:9200/";
  CHECK_STATUS =
    "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/status/";
  INTERNAL_SERVICE2_V1_API = 'https://devinternal-api.picxy.com/service2/o-events/v1/'
  INTERNAL_SERVICE2_V1_QRCODE_API = 'https://devinternal-api.picxy.com/service2/'

  PICXY_API_O_EVENTS = 'https://devpicxy-api.picxy.com/o-events/'
  UPLOAD_API_NEW_URL = 'https://devupload-api.picxy.com/'
  UPLOAD_API_URL = 'https://devpicxy-api.picxy.com/o-events/v1/'
  CREATE_SERVICE2_V1_API = 'https://devpicxy-api.picxy.com/o-events/v1/'
  EVENT_DEV_API = 'https://devpicxy-api.picxy.com/o-events/v1/'
}

module.exports = {
  IS_DEBUG: prod ? false : true,
  EVENT_TYPE: { SPONSOR_EVENT: 1, PAID_EVENT: 2 },
  //RPAY_KEY: prod ? 'rzp_live_nF7dRsgjX53PNB'  : 'rzp_test_45EEsjAP1fdKqm',
  //RPAY_KEY: prod ? "rzp_live_nF7dRsgjX53PNB" : "rzp_test_age8kV4tnxSWC2",
  RPAY_KEY: prod ? "rzp_live_X3gLc4nca6GqNP" : "rzp_test_age8kV4tnxSWC2",
  STRIPE_KEY: prod
    ? "pk_live_seyzGM598zvDr2xcgOjM3qfI00lPe1hHUx"
    : "pk_test_XlbZVWs0aKSi9jXA7KzSlnKI00ft6t8yLs",
  GOOGLE_MAPS_API_KEY: prod
    ? "AIzaSyAAIt7oPb6X9nxNuYg0f2p4ej-Irv74zcw"
    : "AIzaSyDlFi-zRXDapxK1fLPKggz8gWvxAqBt7Jc",
    HOME_URL,

  // open events start
  GET_O_EVENT_DETAIL: PICXY_O_EVENTS_URL + "get_event/event_uuid/",
  GET_O_EVENT_ATTENDEE_DETAIL: PICXY_O_EVENTS_URL + "get_attendee/",
  DOWNLOAD_O_EVENT_PHOTO: PICXY_O_EVENTS_URL + "download",
  REGISTER_O_EVENT_ATTENDEE: PICXY_O_EVENTS_URL + "attendee_register/",

  //  Open events start
  CREATE_O_EVENT: CREATE_SERVICE2_V1_API + 'create_event/',
  GET_O_EVENTS: EVENT_DEV_API + 'get_events',
  UPDATE_O_EVENT: EVENT_DEV_API + 'update_event/',
  GET_O_EVENT_DETAIL_INFO: EVENT_DEV_API + 'get_event/',
  CHANGE_O_EVENT_STATUS: EVENT_DEV_API + 'change_event_status/',

  //GET_O_CATEGORIES: INTERNAL_SERVICE2_V1_API + 'get_categories/',
  GET_O_CATEGORIES: EVENT_DEV_API + 'get_category_list/',  
  GET_O_EVENT_QRCODE: INTERNAL_SERVICE2_V1_QRCODE_API + 'open_events',
  //DOWNLOAD_O_EVENT_QRCODE: INTERNAL_SERVICE2_V1_API + 'download_qrcode/',
  DOWNLOAD_O_EVENT_QRCODE: EVENT_DEV_API + 'download_qrcode/',

  GET_O_EVENT_CSRF: INTERNAL_SERVICE2_V1_API + 'get_csrf',

  UPLOAD_O_EVENT_PHOTO: UPLOAD_API_URL + 'upload_event_photo_cloud/',
  PUBLISH_O_EVENT_PHOTOS: UPLOAD_API_URL + 'publish_event_photos/',
  GET_O_EVENT_PHOTOS: UPLOAD_API_URL + 'get_event_photos/',
  DELETE_O_EVENT_PHOTO: UPLOAD_API_URL + 'delete_event_photo/',
  NOTIFY_PHOTOS_READY: INTERNAL_SERVICE2_V1_API + 'notify_photos_ready',

  //GET_O_EVENT_ATTENDEES: PICXY_API_O_EVENTS + 'get_attendees/event_uuid/',
  GET_O_EVENT_ATTENDEES: EVENT_DEV_API + 'get_attendee_list/',
  // open events end

  PHOTOGRAPHER_PHOTOS_URL: API_URL + "get_photographer_photos/",
  PHOTOGRAPHER_VIDEOS_URL: API_URL + "get_photographer_videos/",

  GET_CONTRIBUTOR_INFO: API_URL + "get_contributor_info/",
  GET_CONTRIBUTOR_PHOTOS: API_URL + "get_contributor_photos/",
  GET_CONTRIBUTOR_VIDEOS: API_URL + "get_contributor_videos/",

  PHONEPAY_KEY: prod ? "d510f77a-f40d-443e-bdf4-3d63973b8258" : "099eb0cd-02cf-4e2a-8aca-3e6c6aff0399",
  CAPTURE_PHONEPAY_URL: API_URL + "capture_paymentPhonePe/",
  HOST_PHONEPAY_URL: API_URL + "pay_phonepe/",
  PHONEPAY_MERCHANT_ID: prod ? "M22EK5HJV21VY" : "PGTESTPAYUAT",

  PHONEPAY_REDIRECT_URL: prod ? "https://www.picxy.com/" : "http://dev.picxy.com/",
  PHONEPAY_CALLBACK_URL: "https://azure-picxy-api.picxy.com/phonepe_payment_webhook",
  //PHONEPAY_HOST_URL:prod ? "https://api.phonepe.com/apis/hermes/pg/v1/pay":"https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay",

  GEO_PHOTOS_URL: API_URL + "get_geo_photos/",
  COLLECTION_PHOTOS_URL: API_URL + "get_collection_photos/",
  COLLECTIONS_URL: API_URL + "get_collections/",
  HOME_DATA_URL: API_URL + "get_home_data/",
  LOGIN_URL: API_URL + "login/",
  GOOGLE_LOGIN_URL: API_URL + "s_login/google/",
  GET_USER_URL: API_URL + "get_user/",
  GET_TEAM_MEMBERS: API_URL + "get_team_members/",
  ADD_TEAM_MEMBER: API_URL + "add_team_member/",
  REMOVE_TEAM_MEMBER: API_URL + "remove_team_member/",
  SEND_INVITATION_TEAM_MEMBER: API_URL + "send_invitation_team_member/",
  GET_AZ_COLLECTIONS: API_URL + "get_collections_by_alphabets/",
  ACCEPT_TERMS_URL: API_URL + "accept_terms/",
  LOGOUT_URL: API_URL + "logout/",
  SET_COOKIE: API_URL + "set_cookie/",
  GET_VIDEO_DETAIL_URL: API_URL + "get_video/",
  GET_PHOTO_DETAIL_URL: API_URL + "get_photo/",
  ADD_PHOTO_TO_CART_URL: API_URL + "add_photo_to_cart/",
  ADD_VIDEO_TO_CART: API_URL + "add_video_to_cart/",

  CREATE_STRIPE_PAYMENT_INTENT: API_URL + "create_payment_intent/",
  CAPTURE_STRIPE_PAYMENT_URL: API_URL + "capture_stripe_payment/",

  HOTJAR_SITEID: prod ? 1307824 : 123456,
  SEARCH_SUGGEST_API:
    SEARCH_SUGGEST_HOST + "search_suggestions/keyword/_search/",
  SEARCH_COLLECTION_API: SEARCH_SUGGEST_HOST + "picxy_collection/_search/",

  REMOVE_VIDEO_FROM_CART_URL: API_URL + "remove_video_from_cart/",
  REMOVE_PHOTO_FROM_CART_URL: API_URL + "remove_photo_from_cart/",
  ADD_BILLING_INFO_URL: API_URL + "add_billing_info/",
  GET_CART_DETAIL_URL: API_URL + "get_cart/",
  GET_BILLING_INFO_URL: API_URL + "get_billing_info/",
  DOWNLOAD_POSTPAID_INVOICE: API_URL + "download_postpaid_invoice/",
  DOWNLOAD_INVOICE: API_URL + "download_invoice/",
  DOWNLOAD_PHOTO_FROM_ORDER: API_URL + "download_photo_from_order/",
  DOWNLOAD_VIDEO_FROM_ORDER: API_URL + "download_video_from_order/",
  GET_PLAN_INFO_URL: API_URL + "get_subscripion_plan_info/",
  GET_CART_WITH_BILLING_INFO_URL: API_URL + "get_cart_with_billing_info/",
  CAPTURE_POSTPAID_PAYMENT: API_URL + "capture_postpaid_bill_payment/",
  CAPTURE_PAYMENT_URL: API_URL + "capture_payment",

  GET_CUSTOMER_ORDERS_URL: API_URL + "get_customer_orders/",
  GET_CUSTOMER_SUBSCRIPTION: API_URL + "get_user_subscription/",
  GET_CUSTOMER_OWNED_SUBSCRIPTION: API_URL + "get_user_owned_subscription/",
  GET_PAYMENT_HISTORY: API_URL + "get_payemt_history/",
  DOWNLOAD_PAYMENT_INVOICE: API_URL + "download_payment_invoice/",

  GET_ALL_PHOTO_DOWNLOADS: API_URL + "get_all_photo_downloads/",
  GET_ALL_VIDEO_DOWNLOADS: API_URL + "get_all_video_downloads/",

  GET_SUBSCRIPTIONS_DOWNLOADS: API_URL + "get_subscriptions_downloads/",
  GET_EVENT_PHOTOS: API_URL + "get_event_photos/",
  GET_CONTEST_DETAILS: API_URL + "get_contest_details/",
  GET_CONTESTS: API_URL + "get_contests/",
  GET_CONTEST_PHOTOS: API_URL + "get_contest_photos/",
  GET_CONTEST_WINNERS: API_URL + "get_contest_winners",
  GET_WALLPAPERS_PHOTOS: API_URL + "get_wallpapers/",
  DOWNLOAD_WALLPAPER: API_URL + "download_wallpaper/",

  DOWNLOAD_VIDEO_URL: API_URL + "download_video/",

  GET_POSTPAID_SUBSCRIPTION_BILLS: API_URL + "get_postpaid_subscription_bills/",

  CANCEL_SUBSCRIPTON: API_URL + "cancel_subscription/",
  CREATE_SUBSCRIPTION: API_URL + "create_subscription/",
  VERIFY_SUBSCRIPTION_PAYMENT: API_URL + "verify_subscription_payment/",
  VERIFY_VALUE_PACK: API_URL + "verify_value_pack/",
  CREATE_VALUE_PACK: API_URL + "create_valuepack/",
  STRIPE_CREATE_VALUE_PACK: API_URL + "stripe_create_valuepack/",
  DOWNLOAD_PHOTO_FROM_SUBSCRIPTION:
    API_URL + "download_photo_from_subscription/",
  DOWNLOAD_VIDEO_FROM_SUBSCRIPTION:
    API_URL + "download_video_from_subscription/",
  RE_DOWNLOAD_PHOTO_FROM_SUBSCRIPTION:
    API_URL + "re_download_under_subscription/",
  SEARCH_PHOTOS: API_URL + "search_photos/",
  SEARCH_VIDEOS: API_URL + "search_videos/",
  CATEGORY_PHOTOS: API_URL + "get_category_photos/",
  RELATED_PHOTOS: API_URL + "get_related_photos/",
  GET_TESTIMONIALS_URL: API_URL + "get_testimonials/",
  CONTACT_US: API_URL + "contactus/",
  FREE_CREDITS_REQUEST: API_URL + "free_credits_request/",
  PASSWORD_RESET: API_URL + "password_reset/",
  PASSWORD_RESET_CONFIRM: API_URL + "password_reset/confirm/",
  SING_UP: API_URL + "register/",
  ACTIVATE: API_URL + "activate/",
  APPLY_COUPON: API_URL + "apply_coupon/",
  APPLY_COUPON_VALUPACK: API_URL + "apply_coupon_valuepack/",
  APPLY_COUPON_SUBCRIPTION_PLAN: API_URL + "apply_coupon_on_subscription_plan/",
  CREATE_CAPTURE_FACE_SYNC_PAYMENT: API_URL + "payments/v1/capture_face_sync_payment/",
  CHECK_PAYMENT_STATUS: API_URL + "check_payment_status/",
  CHECK_FACESYNC_PAYMENT_STATUS: API_URL + "payments/v1/check_payment_status/",
  CHECK_EVENT_CREATE_STATUS: API_URL + "o-events/v1/check_event_create_status/",
  CHECK_PHONE_NUMBER_EXISTS: API_URL + "users/phone-number-exist/",
  UPDATE_USER_CONTACT_NUMBER: API_URL + "users/update-contact-number/",
  GET_USER_LIKES_URL: API_URL + "get_user_likes/",
  ADD_TO_FAVOURITES_URL: API_URL + "add_to_likes/",
  CHANGE_PASSWORD_URL: API_URL + "change_password/",
  UPDATE_PROFILE_URL: API_URL + "update_profile/",
  SUBMIT_FEEDBACK_URL: API_URL + "submit_feedback/",
  CUSTOM_SHOOT: API_URL + "custom_shoot/",
  USER_CHECKOUT: API_URL + "user_checkout_clicked",
  // Extras
  ASSET_URL: prod
    ? "https://storage.googleapis.com/picxystatic/"
    : "https://storage.googleapis.com/picxystatic/",
  ASSET_PROFILE_URL: prod
    ? "https://www.picxy.com/static/"
    : "http://localhost:8001/static/",
  SITE_URL: prod ? "https://www.picxy.com" : "http://dev.picxy.com",
  TOKEN: "token",
  CART: "cart",
  LIKES: "likes",
  EVENT_PHOTOS_URL: prod ? "" : "http://127.0.0.1:8001/static/events/",
  CONTEST_PHOTOS_URL: prod ? "" : "http://localhost:8001/static/",
  // SENTRY_DSN: prod?"https://a124aa78307b40e4b8998e5d35b38a8f@o173734.ingest.sentry.io/1533914":'',
  SENTRY_DSN: "",
  RECENT_SEARCH_TERMS: "recent_search_terms",
  RECENT_IMAGES: "recent_images",
  CURRENT_SEARCH_TERM: "current_search_term",

  UPLOAD_PICXY_URL_REDIRECT: prod
    ? "https://upload.picxy.com/login?token="
    : "http://localhost:3002/login?token=",
  UPLOAD_PICXY: prod ? "http://upload.picxy.com/login" :
    "https://devupload.picxy.com/login",
  UPLOAD_LOGIN_URL: UPLOAD_API + "login/",
  UPLOAD_GOOGLE_LOGIN_URL: UPLOAD_API + "s_login/google/",
  UPLOAD_SIGNUP_URL: UPLOAD_API + "register/",
  UPLOAD_IS_REFERRAL_CODE_VALID: UPLOAD_API + "is_referral_code_valid/",

  REQUEST_FREE_CREDITS_URL: API_URL + "register_free_credit_users/",
  GET_FREE_CREDITS_USER_URL: API_URL + "get_free_credit_user/",
  ACTIVATE_WITH_REDIRECTION: API_URL + "activate/ft/",
  DOWNLOAD_SAMPLE: API_URL + "download_sample_photo/",

  SEARCH_PHOTOS_NEW: API_URL + "search_photosNew/",
  CHECK_STATUS_URL: CHECK_STATUS,
  QUICK_ACCESS_COLLECTIONS: prod ? API_URL + "get_home_data/" : API_URL + "get_home_data/",
  BOOK_PHOTOGRAPHER: prod ? API_URL + "book_a_photographer/" : API_URL + "book_a_photographer/",
  //GET_O_EVENTS: INTERNAL_SERVICE2_V1_API + 'get_events',
};

// APIS
