import { storageFactory } from "storage-factory";

export const lStorage = storageFactory(() => localStorage);
export const sStorage = storageFactory(() => sessionStorage);

export function setSessionKeyValue(key, value) {
  sStorage.setItem(key, value)
}

export function getSessionKeyValue(key) {
  return sStorage.getItem(key)
}
export function removeSessionStorageKey(kye){
  sStorage.removeItem(key)
}
export function removeLocalKey(key){
  lStorage.removeItem(key)
}

export function setLocalKeyValue(key, value) {

  lStorage.setItem(key, value)
}

export function getLocalKeyValue(key) {
  return lStorage.getItem(key)
}

export function getJsonData(key) {
  if (typeof localStorage[key] !== "undefined" && localStorage[key] !== null) {
    try {
        return JSON.parse(localStorage[key])
    } catch (e) {
      return undefined
    }

  }
}

export function setJsonData(key, value) {
  localStorage[key] = JSON.stringify(value)
}
